<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full flex flex-col justify-center py-12 px-4 sm:px-4 lg:px-8 overflow-scroll">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
           <img class="block h-10 m-auto" :src="require('@/assets/logo.png')" alt="Savvy" />
      <h2 class="mt-6 text-center text-2xl font-bold text-green-800">
        Sign up to Savvy
      </h2>
      <p class="mt-2 text-center text-lg text-gray-600">
        Or
        {{ ' ' }}
         <router-link href="signup" class="font-medium text-green-600 hover:text-green-500 " :to="'signin'">
          log in with existing account
        </router-link>
      </p>
    </div>

    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md overflow-scroll">
      <div class="bg-white py-8 px-4 rounded-xl">
        <form class="grid grid-cols-4 gap-y-2 gap-x-4" action="#" method="POST">
          <div class="col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <div class="mt-1">
              <input id="name" v-model="form.name" name="name" type="text" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
              <!-- Error Message -->
              <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
                <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
              </div>
            </div>
          </div>
           <div class="col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <div class="mt-1">
              <input v-model="form.lastname" id="name" name="name" type="text" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
              <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.lastname.$errors" :key="index">
              <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
            </div>
            </div>
          </div>
          <div class="col-span-4">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input v-model="form.email" id="email" name="email" type="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
              <!-- Error Message -->
              <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-span-4">
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input v-model="form.pass" id="password" name="password" type="password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
              <!-- Error Message -->
              <div class="input-errors" v-for="(error, index) of v$.form.pass.$errors" :key="index">
                <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-span-4">
            <label for="password" class="block text-sm font-medium text-gray-700">
              Re-enter Password
            </label>
            <div class="mt-1">
              <input v-model="form.passconf" id="password" name="password" type="password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
              <!-- Error Message -->
              <div class="input-errors" v-for="(error, index) of v$.form.passconf.$errors" :key="index">
                <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="col-span-4">
            <div class="flex items-center">
               <input id="terms" v-model="form.terms" type="checkbox" class="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded" />
            <span class="text-grey-600 ml-2 text-sm">I've read and accept <span href="#" class="text-green-600 font-semibold">Savvy terms of use.</span></span>
            </div>
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.terms.$errors" :key="index">
              <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
            </div>
          </div>

          <div class="pt-6 col-span-4">
            <savvy-button :disabled="!v$.form.$invalid" @click="submitForm()">
              Sign up
            </savvy-button>
          </div>
        </form>

     


 
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength,maxLength, sameAs, helpers } from '@vuelidate/validators'
import SavvyButton from '@/components/button.vue'

export default  {
  components:{
    SavvyButton
  },
  setup () {
    return { v$: useVuelidate() }
  },

  data(){
    return{
      form:{
        name:null,
        lastname:null,
        email:null,
        pass:null,
        passconf:null,
        terms:false
      }
      
    }
  },
  validations() {
    return {
      form: {
        name:{
           required,
           min:minLength(2),
           max:maxLength(30)
        },
        lastname:{
           required,
           min: minLength(2),
           max:maxLength(30)
        },
        email: {
           required, email,
           max:maxLength(50) 
        },
        pass: {
            required, 
            min: minLength(6),
            max:maxLength(30)
        },
        passconf:{
           required,
           min: minLength(6),
           max:maxLength(30),
           sameAsPassword: helpers.withMessage('This field needs to has same value as password filed', sameAs(this.form.pass))
        },
        terms:{
           required,
           sameAsRawValue: helpers.withMessage('This field needs to be checked',sameAs(true))
        },
      },
    }
  },
  methods:{
    async submitForm(){
      const isFormCorrect = await this.v$.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (isFormCorrect){
        this.signUp()
      }
    },
    signUp(){
       this.$store.dispatch('signUpUser', {
            form:Object.assign({},this.form)
          }).then(() => {
            this.$router.push('deposit')
          })
    }
  }
}
</script>